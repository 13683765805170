// frappe.ui.keys.add_shortcut({
//     description: "Show Balance Sheet",
//     shortcut:"alt+b",
//     action:()=>{
//         frappe.set_route("List","Journal Entry");
//     }
// })

// fraa

$(document).ready(function () {
    if (frappe.meta.has_field(cur_frm.doctype, "company")) {
        frappe.call({
            method: "frappe.client.get_value",
            args: {
                doctype: "Company",
                fieldname: "default_letter_head",
                filters: { name: cur_frm.doc.company },
            },
            callback: function (res) {
                if (res && res.message) {
                    cur_frm.doc.letter_head = res.message.default_letter_head
                    refresh_field("letter_head");
                }
            }
        });
    }
});